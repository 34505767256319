import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

const Dashboard = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ "../views/Dashboard")
);
const Contact = lazy(() =>
  import(/* webpackChunkName: "contact" */ "../views/Contact")
);
const Product = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Product")
);
const DetailProduct = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Product/DetailProduct")
);
const AboutUs = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/AboutUs")
);
const News = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/News")
);
const Blog = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Blog")
);
const DetailNewsEvent = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/News/DetailNewsEvent")
);
const DetailBlog = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Blog/DetailBlog")
);
const Reseller = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/Reseller")
);
const RecommendationVirus = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/RecommendationVirus")
);
const RecommendationAutomotive = lazy(() =>
  import(/* webpackChunkName: "product" */ "../views/RecommendationAutomotive")
);

function Routes() {
  return (
    <main style={{ marginTop: 64 }}>
      <Suspense fallback={<></>}>
        <Switch>
          <Route path="/our-location" component={Contact} />
          <Route path="/contact-us" component={Contact} />
          <Route path="/product/:id" component={DetailProduct} />
          <Route path="/product" component={Product} />
          <Route path="/news/:id" component={DetailNewsEvent} />
          <Route path="/news" component={News} />
          <Route path="/blogs" component={Blog} />
          <Route path="/blog/:id" component={DetailBlog} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/reseller" component={Reseller} />
          <Route path="/recommendation-virus" component={RecommendationVirus} />
          <Route
            path="/recommendation-automotive"
            component={RecommendationAutomotive}
          />
          <Route path="/" exact component={Dashboard} />
        </Switch>
      </Suspense>
    </main>
  );
}

export default Routes;
