import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { FirstFooter, SecondFooter } from "./styleComponent";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

function Index({ dataCategoryProduct, dataAboutUs }) {
  const history = useHistory();
  return (
    <>
      {/* MEDSOS */}
      <Grid
        style={{
          backgroundColor: "#ff0000",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          style={{
            maxWidth: 950,
            padding: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <b style={{ marginRight: 20, color: "white" }}>Follow Us</b>
          {/* <FacebookIcon style={{ marginRight: 20, cursor: 'pointer' }} onClick={() => window.open('https://www.facebook.com/betonperkasawijaksanaofficial/')} />
          <InstagramIcon style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.instagram.com/betonperkasawijaksana/')} /> */}
          <FacebookIcon
            style={{ marginRight: 20, cursor: "pointer", color: "white" }}
          />
          <InstagramIcon style={{ cursor: "pointer", color: "white" }} />
        </Grid>
      </Grid>
      {/* #ff0000 */}
      <Grid
        style={{
          backgroundColor: "#aeaeae",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          style={{
            maxWidth: 950,
            padding: "20px 0px",
            display: "flex",
          }}
        >
          <FirstFooter item xs={12} md={5}>
            <h3 style={{ margin: 0 }}>PT Pola Lubindo</h3>
            {/* <h4 style={{ margin: 0, marginBottom: 20 }}>Total Solution Formwork & Scaffolding</h4> */}
            <b style={{ fontSize: 15 }}>Kantor Pusat</b>
            <p style={{ fontSize: 14 }}>Jl. Penjernihan No 40</p>
            <p style={{ fontSize: 14 }}>Jakarta Pusat, Jakarta - Indonesia</p>
            <p style={{ fontSize: 14 }}>Phone : +62(21) 571 2644</p>
            <p style={{ fontSize: 14 }}>Fax : +62(21) 571 2644</p>
            <p style={{ fontSize: 14 }}>Mail : sales@polalubindo.co.id</p>
            <p style={{ fontSize: 14 }}>Website : www.polalubindo.com</p>
          </FirstFooter>

          <SecondFooter item md={7}>
            <Grid style={{ width: "40%" }}>
              <b style={{ fontSize: 15 }}>Produk</b>
              {dataCategoryProduct.map((categoryProduct, index) => (
                <p
                  style={{ fontSize: 14, cursor: "pointer" }}
                  onClick={() =>
                    history.push("/product", { id: categoryProduct.id })
                  }
                  key={`categoryProduct-${index}`}
                >
                  {categoryProduct.name}
                </p>
              ))}
            </Grid>
            <Grid style={{ width: "40%" }}>
              <b style={{ fontSize: 15 }}>Tentang Kami</b>
              {dataAboutUs.map((aboutUs, index) => (
                <p
                  style={{ fontSize: 14, cursor: "pointer" }}
                  onClick={() =>
                    history.push("/about-us", { id: aboutUs.id, data: aboutUs })
                  }
                  key={`aboutUs-${index}`}
                >
                  {aboutUs.title}
                </p>
              ))}
            </Grid>
          </SecondFooter>
        </Grid>
      </Grid>
      <Grid
        style={{
          backgroundColor: "#303030",
          padding: "30px auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 50,
        }}
      >
        <p style={{ color: "#ffffff", fontSize: 10 }}>
          © Copyright 2022 PT Pola Lubindo, All Right Reserved
        </p>
      </Grid>
    </>
  );
}

const mapStateToProps = ({ dataCategoryProduct, dataAboutUs }) => {
  return {
    dataCategoryProduct,
    dataAboutUs,
  };
};

export default connect(mapStateToProps)(Index);
