import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Grid,
  // Link,
  // Collapse
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import WhatsappIcon from "../../assets/whatsapp.png";

import { NavMenu, BurgerMenu, Nav, ContainerGrid } from "./styleComponent";

import Logo from "../../assets/logo.png";

import {
  fetchDataCategoryProduct,
  fetchDataService,
  fetchDataAboutUs,
  fetchDataNews,
  fetchDataBlog,
  fetchDataEvent,
  fetchBanner,
  fetchMetaTag,
} from "../../store/action";
// import { BaseURL } from '../../config/API';

function Navbar({
  fetchMetaTag,
  fetchDataCategoryProduct,
  fetchDataService,
  dataCategoryProduct,
  fetchDataAboutUs,
  fetchDataNews,
  fetchDataBlog,
  fetchDataEvent,
  dataAboutUs,
  fetchBanner,
}) {
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [menuSelected, setMenuSelected] = React.useState(null);
  // const [menuDrawerSelected, setMenuDrawerSelected] = React.useState(null);

  React.useEffect(() => {
    async function fetch() {
      await fetchMetaTag();
      fetchDataAboutUs();
      fetchDataCategoryProduct();
      fetchDataService();
      fetchDataNews();
      fetchDataEvent();
      fetchBanner();
      fetchDataBlog();
    }
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleMenuSelected = (event, args) => {
    setMenuSelected(args);
    if (args === "recommendation") {
      // setMenuSelected(args)
    } else {
      history.push(`/${args}`);
    }
  };

  const handleMenuDrawerSelected = (event, args) => {
    // setMenuDrawerSelected(args)
    setOpenDrawer(false);
    history.push(`/${args}`);
  };

  const navigate = (args, data) => () => {
    setMenuSelected(null);
    // setMenuDrawerSelected(null)
    setOpenDrawer(false);

    if (data) history.push(args, { id: data });
    else history.push(args);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        style={{
          maxWidth: "1920px",
          left: 0,
          margin: "0px auto",
        }}
      >
        <Toolbar
          style={{
            height: 64,
            width: "100%",
            maxWidth: "1920px",
            backgroundColor: "#ffffff",
            padding: 0,
          }}
        >
          <ContainerGrid>
            <img
              src={Logo}
              style={{ width: "120px", cursor: "pointer" }}
              alt="logo-PL"
              onClick={navigate("/")}
            />

            <NavMenu>
              <Nav
                onClick={(event) => handleMenuSelected(event, "product")}
                style={{
                  borderBottom: menuSelected === "product" && "5px solid red",
                  padding: menuSelected === "product" && "10px 15px 6px",
                }}
              >
                Produk
              </Nav>
              <Nav
                onClick={(event) => handleMenuSelected(event, "about-us")}
                style={{
                  borderBottom: menuSelected === "about-us" && "5px solid red",
                  padding: menuSelected === "about-us" && "10px 15px 6px",
                }}
              >
                Tentang Kami
              </Nav>
              {/* <Nav
                onClick={(event) => handleMenuSelected(event, "news")}
                style={{
                  borderBottom: menuSelected === "news" && "5px solid red",
                  padding: menuSelected === "news" && "10px 15px 6px",
                }}
              >
                Berita
              </Nav> */}
              <Nav
                onClick={(event) => handleMenuSelected(event, "blogs")}
                style={{
                  borderBottom: menuSelected === "blog" && "5px solid red",
                  padding: menuSelected === "blog" && "10px 15px 6px",
                }}
              >
                Blog
              </Nav>
              <Nav
                onClick={(event) => handleMenuSelected(event, "reseller")}
                style={{
                  borderBottom: menuSelected === "reseller" && "5px solid red",
                  padding: menuSelected === "reseller" && "10px 15px 6px",
                }}
              >
                Reseller
              </Nav>
              <Nav
                onClick={(event) => handleMenuSelected(event, "contact-us")}
                style={{
                  borderBottom:
                    menuSelected === "contact-us" && "5px solid red",
                  padding: menuSelected === "contact-us" && "10px 15px 6px",
                }}
              >
                Hubungi Kami
              </Nav>
              <Nav
                onClick={(event) => handleMenuSelected(event, "recommendation")}
                style={{
                  borderBottom:
                    menuSelected === "recommendation" && "5px solid red",
                  padding: menuSelected === "recommendation" && "10px 15px 6px",
                }}
              >
                Rekomendasi
              </Nav>
            </NavMenu>

            <BurgerMenu>
              <MenuIcon onClick={() => setOpenDrawer(!openDrawer)} />
            </BurgerMenu>
          </ContainerGrid>
        </Toolbar>
        {menuSelected === "recommendation" ? (
          <Box
            style={{
              backgroundColor: "#ebeced",
              padding: "20px 10px",
              display: "flex",
              overflowX: "auto",
              alignItems: "center",
            }}
            onClick={() => setMenuSelected(null)}
          >
            <Grid
              style={{
                width: 177,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "1%",
                marginRight: "2%",
                cursor: "pointer",
              }}
              onClick={navigate("/recommendation-virus")}
            >
              <b style={{ color: "#606060", textAlign: "center" }}>
                Virus Keeper
              </b>
            </Grid>
            <Grid
              style={{
                width: 177,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "1%",
                marginRight: "2%",
                cursor: "pointer",
              }}
              onClick={navigate("/recommendation-automotive")}
            >
              <b style={{ color: "#606060", textAlign: "center" }}>
                Automotive Lubricant
              </b>
            </Grid>
          </Box>
        ) : (
          ""
        )}

        <Drawer
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            // onClick={() => setOpenDrawer(false)}
            // onKeyDown={() => setOpenDrawer(false)}
          >
            <List>
              <ListItem
                button
                key="Product"
                onClick={(event) => handleMenuDrawerSelected(event, "product")}
              >
                <ListItemText primary="Product" />
              </ListItem>
              <ListItem
                button
                key="Tentang Kami"
                onClick={(event) => handleMenuDrawerSelected(event, "about-us")}
              >
                <ListItemText primary="Tentang Kami" />
              </ListItem>
              {/* <ListItem
                button
                key="News"
                onClick={(event) => handleMenuDrawerSelected(event, "news")}
              >
                <ListItemText primary="Berita" />
              </ListItem> */}
              <ListItem
                button
                key="Blog"
                onClick={(event) => handleMenuDrawerSelected(event, "blogs")}
              >
                <ListItemText primary="Blog" />
              </ListItem>
              <ListItem
                button
                key="Reseller"
                onClick={(event) => handleMenuDrawerSelected(event, "reseller")}
              >
                <ListItemText primary="Reseller" />
              </ListItem>
              <ListItem
                button
                key="Hubungi Kami"
                onClick={(event) =>
                  handleMenuDrawerSelected(event, "contact-us")
                }
              >
                <ListItemText primary="Hubungi Kami" />
              </ListItem>
              <ListItem
                button
                key="Recommendation"
                onClick={(event) =>
                  handleMenuDrawerSelected(event, "recommendation")
                }
              >
                <ListItemText primary="Recommendation" />
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </AppBar>
      <div style={{ zIndex: 99, position: "fixed", right: 15, bottom: 15 }}>
        <img
          src={WhatsappIcon}
          alt="contact-us"
          style={{
            width: 50,
            height: 50,
            marginRight: 10,
            cursor: "pointer",
          }}
          onClick={() =>
            window.open(
              `https://api.whatsapp.com/send?phone=+6281806152968&text=Halo, saya ingin menanyakan tentang ...`
            )
          }
        />
      </div>
    </Box>
  );
}

const mapStateToProps = ({ dataCategoryProduct, dataAboutUs }) => {
  return {
    dataCategoryProduct,
    dataAboutUs,
  };
};

const mapDispatchToProps = {
  fetchDataCategoryProduct,
  fetchDataService,
  fetchDataAboutUs,
  fetchDataNews,
  fetchDataBlog,
  fetchDataEvent,
  fetchBanner,
  fetchMetaTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
