import {
  IconButton,
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink as Link } from 'react-router-dom';

export const ContainerGrid = styled(Grid)(({ theme }) => ({
  padding: '0px 10px',
  width: '100%', 
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    padding: '0px 30px',
  },
}));

export const NavLink = styled(Link)(({ theme }) => ({
  color: '#606060',
  cursor: 'pointer',
  padding: '10px 15px',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: '#fafafa',
    padding: '5px 10px 1px',
    borderBottom: '5px solid #ff0000',
  },
  // '&.active': {
  //   padding: '5px 10px 1px',
  //   borderBottom: '5px solid #ff0000',
  // }
}));

export const Nav = styled(Grid)(({ theme }) => ({
  color: '#606060',
  cursor: 'pointer',
  padding: '10px 15px',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: '#fafafa',
    padding: '10px 15px 6px',
    borderBottom: '5px solid #ff0000',
  },
  // '&.active': {
  //   padding: '5px 10px 1px',
  //   borderBottom: '5px solid #ff0000',
  // }
}));

export const NavMenu = styled(Grid)(({ theme }) => ({
  display: 'none',
  alignItems: 'center',
  justifyContent: 'space-around',

  [theme.breakpoints.up('sm')]: {
    display: 'flex'
  },
}));

export const BurgerMenu = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    display: 'none'
  },
}));

