import axios from "axios";

// const BaseURL = "http://localhost:4000";
const BaseURL = "https://apibasicweb.polalubindo.co.id";
const API = axios.create({
  baseURL: BaseURL,
});

export { API, BaseURL };
// import { browserName, osName, isMobile } from "react-device-detect";
// const BaseURL = 'http://157.230.248.17:4000'; //digital ocean
